@import './colors.css';

/* Hierarchical Text Elements */
.title {
  font-size: 2.5em;
  font-weight: bold;
  text-align: center;
  margin: 0.8em 0;
  color: var(--text-color-primary); /* Matches default black */
}

.subtitle {
  font-size: 1.3em;
  font-weight: 600;
  text-align: center;
  margin: 0.6em 0;
  color: var(--text-color-primary); /* Matches default black */
}

.heading {
  font-size: 2em;
  font-weight: bold;
  margin: 0.8em 0;
  color: var(--text-color-primary); /* Matches default black */
}

.subheading {
  font-size: 1.5em;
  font-weight: 600;
  margin: 0.6em 0;
  color: var(--text-color-primary); /* Matches default black */
}

.byline {
  font-style: italic;
  font-size: 1em;
  text-align: right;
  margin: 0.6em 0;
  color: var(--neutral-dark); /* Matches gray */
}

.caption {
  font-size: 0.9em;
  font-style: italic;
  text-align: center;
  color: var(--neutral-dark); /* Matches gray */
  margin-top: 0.3em;
}

.header {
  font-size: 1.2em;
  font-weight: bold;
  text-align: left;
  margin: 0.4em 0;
  color: var(--text-color-primary); /* Matches default black */
}

.footer {
  font-size: 0.9em;
  text-align: right;
  margin: 0.4em 0;
  color: var(--text-color-primary); /* Matches default black */
}

/* Body Text Elements */
.body-text {
  font-size: 1em;
  line-height: 1.6;
  margin: 0.6em 0;
  color: var(--text-color-primary); /* Matches default black */
}

.lead-paragraph {
  font-size: 1.2em;
  font-weight: bold;
  margin: 0.8em 0;
  color: var(--text-color-primary); /* Matches default black */
}

.pull-quote {
  font-size: 1.5em;
  font-style: italic;
  text-align: center;
  margin: 0.8em auto;
  border-left: 0.25em solid var(--neutral-medium); /* Matches #ccc */
  padding-left: 0.6em;
  color: var(--text-color-primary); /* Matches default black */
}

/* Traditional Printing Elements */
.masthead {
  font-size: 2em;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  margin: 0.8em 0;
  color: var(--text-color-primary); /* Matches default black */
}

.deck {
  font-size: 1.3em;
  font-style: italic;
  margin: 0.6em 0;
  color: var(--text-color-primary); /* Matches default black */
}

.slugline {
  font-size: 0.9em;
  text-transform: uppercase;
  color: var(--neutral-dark); /* Matches gray */
  margin: 0.6em 0;
}

.running-header {
  font-size: 0.9em;
  text-align: left;
  margin: 0.4em 0;
  color: var(--text-color-primary); /* Matches default black */
}

.running-footer {
  font-size: 0.9em;
  text-align: right;
  margin: 0.4em 0;
  color: var(--text-color-primary); /* Matches default black */
}

.colophon {
  font-size: 0.8em;
  text-align: center;
  color: var(--neutral-dark); /* Matches gray */
  margin-top: 3.125em;
}

/* Decorative Text */
.drop-cap {
  font-size: 3em;
  float: left;
  line-height: 1;
  margin-right: 0.6em;
  font-weight: bold;
  color: var(--text-color-primary); /* Matches default black */
}

.side-note {
  font-size: 0.9em;
  color: var(--neutral-dark); /* Matches gray */
  margin: 0.6em 0;
  float: right;
  width: 30%;
}

.endnote {
  font-size: 0.9em;
  margin: 0.6em 0;
  color: var(--neutral-dark); /* Matches gray */
}

.epigraph {
  font-size: 1.2em;
  font-style: italic;
  text-align: center;
  margin: 1.875em 0;
  color: var(--text-color-primary); /* Matches default black */
}

.callout {
  font-size: 1em;
  font-weight: bold;
  background-color: var(--neutral-light); /* Matches #f4f4f4 */
  padding: 0.6em;
  border: 0.0625em solid var(--neutral-medium); /* Matches #ccc */
  margin: 0.8em 0;
  color: var(--text-color-primary); /* Matches default black */
}

/* Contemporary Digital-Specific Text */
.tagline {
  font-size: 1.1em;
  font-style: italic;
  color: var(--secondary-text-color); /* Matches #333 */
  margin: 0.6em 0;
}

.cta {
  font-size: 1.2em;
  font-weight: bold;
  color: var(--text-color-secondary); /* Matches white */
  background-color: var(--primary-color); /* Matches #007bff */
  padding: 0.6em 1.25em;
  text-align: center;
  display: inline-block;
  cursor: pointer;
  border-radius: 0.3125em;
  text-decoration: none;
}

.meta-description {
  font-size: 0.9em;
  color: var(--neutral-dark); /* Matches gray */
  margin: 0.6em 0;
}

.placeholder-text {
  font-size: 1em;
  color: var(--neutral-placeholder); /* Matches #aaa */
  font-style: italic;
}

.image-icon-text {
  font-size: 0.9em;
  font-weight: bold;
  margin: 0 0;
  color: var(--text-color-primary); /* Matches default black */
}
