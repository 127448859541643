/* Subtle shadow for small elements */
.subtle-shadow {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  /* Medium shadow for cards and elements that need more depth */
  .medium-shadow {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  /* Large OS-style window shadow */
  .window-shadow {
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.15);
  }
  
  /* Inner shadow to create inset/depression effect */
  .inset-shadow {
    box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.2);
  }
  
  /* Deep inset shadow for slider tracks or recessed elements */
  .slider-track-shadow {
    box-shadow: inset 0 2px 8px rgba(0, 0, 0, 0.3);
  }
  
  /* Metal panel cutout effect - combines inset shadow with subtle gradient */
  .panel-cutout {
    background: linear-gradient(to bottom, #e0e0e0, #f5f5f5);
    box-shadow: 
      inset 0 2px 5px rgba(0, 0, 0, 0.3),
      inset 0 -1px 2px rgba(255, 255, 255, 0.5);
    border-radius: 4px;
  }