:root {
  /* Primary Colors */
  --primary-color: #007bff; /* Used for CTA background */

  /* Secondary Colors */
  --secondary-text-color: #333; /* Used for tagline text */

  /* Neutral Colors */
  --neutral-light: #f4f4f4; /* Used for callout background */
  --neutral-medium: #ccc; /* Used for pull-quote and callout border */
  --neutral-dark: gray; /* Used for captions, slugline, side-note, and endnote */
  --neutral-placeholder: #aaa; /* Used for placeholder text */

  /* Text Colors */
  --text-color-primary: black; /* Default text color */
  --text-color-secondary: white; /* Used for CTA text */
}

.force-white {
  filter: invert(1) brightness(2);
}
