/*.menuButton {
    background: none;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
    color: #fff;
    padding: 0px 5px;
  }
  
  .menuButton:hover {
    background-color: rgba(0, 0, 0, 0.5); 
    color: #fff; 
  }

  .menuItem {
    padding: 4px 8px;

    transition: background-color 0.2s ease, color 0.2s ease;
    color: black;
    cursor: pointer;
  }
  
  .menuItem:hover {
    background-color: rgba(0, 0, 0, 1); 
  }
    */

.menuBar {
  display: flex;
  gap: 15px;
  padding: 10px;
}

.menuWrapper {
  position: relative;
}

.menuButton {
  color: white;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 17px;
  font-weight: normal;
}

.menuButton:hover {
  font-weight: bold;
}

.menuContainer {
  z-index: 1000;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  padding: 6px 0;
  min-width: 160px;
  display: flex;
  flex-direction: column;
}

.menuItem {
  position: relative; /* Add this for submenu positioning */
  padding: 8px 16px;
  cursor: pointer;
  transition: background-color 0.2s ease;
  font-size: 14px;
  color: black;
}

.menuItem:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
.HeaderContainer {
  overflow: visible !important; /* Allow children to overflow */
  position: static; /* Prevent confinement */
}

.submenuArrow {
  position: absolute;
  right: 10px; /* Position the arrow to the right */
  font-size: 12px; /* Optional: Smaller size for the arrow */
}

.submenuContainer {
  z-index: 1000;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  padding: 6px 0;
  min-width: 160px;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 100%; /* Position the submenu to the right of the parent item */
}