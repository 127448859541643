/* Reset Styles */
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Root elements and viewport control */
html, body, #root {
  width: 100%;
  height: 100vh; /* Using viewport height consistently */
  margin: 0;
  padding: 0;
  overflow: hidden; /* Prevent scrollbars at the root level */
}

/* User interaction settings */
html, body {
  scrollbar-width: thin; /* For Firefox: makes the scrollbar narrower */
  user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

/* Custom Base Styles */
body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 
               'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* React root element */
#root {
  display: flex;
  flex-direction: column;
}

/* Code blocks */
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* Scrollbar styling - WebKit browsers (Chrome, Safari, Edge) */


::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.4);
}

::-webkit-scrollbar-corner {
  background: transparent;
}

/* App container - add this if needed for your specific app structure */
.app {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}