/* Fade-in Animation */
@keyframes fade-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  .fade-in {
    animation: fade-in 0.5s ease-in-out;
  }
  
  /* Jump Animation */
  @keyframes jump {
    0%, 100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-10px);
    }
  }
  
  .jump {
    animation: jump 0.5s ease-in-out;
  }
  
  /* Zoom-in Animation */
  @keyframes zoom-in {
    from {
      transform: scale(0.5);
      opacity: 0;
    }
    to {
      transform: scale(1);
      opacity: 1;
    }
  }
  
  .zoom-in {
    animation: zoom-in 0.5s ease-in-out;
  }
  
  /* Spin Animation */
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
  .spin {
    animation: spin 5s linear infinite;
  }
  
  /* Scale-Up Animation */
  @keyframes scale-up {
    from {
      transform: scale(0.8);
    }
    to {
      transform: scale(1);
    }
  }
  
  .scale-up {
    animation: scale-up 0.3s ease-in-out;
  }
  
  .icon-container {
    transition: transform 0.2s ease-in-out;
  }
  
  .icon-container:hover {
    transform: rotate(var(--rotation, 0)) scale(0.9) !important; /* Combine rotation and scale */
  }

  @keyframes click-bounce {
    0% {
      transform: scale(1) rotate(var(--rotation, 0deg));
    }
    50% {
      transform: scale(0.8) rotate(var(--rotation, 0deg));
    }
    100% {
      transform: scale(1) rotate(var(--rotation, 0deg));
    }
  }
  
  .click-animation {
    animation: click-bounce 0.2s ease-in-out;
  }
  