/* dragdrop.css */
.drag-preview {
    position: fixed;
    pointer-events: none;
    z-index: 1000;
    background: white;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.2);
    transform: translate(-50%, -50%);
  }
  
  .draggable {
    cursor: grab;
    user-select: none;
  }
  
  .draggable.dragging {
    opacity: 0.5;
  }
  
  .dropzone {
    min-height: 100px;
    transition: background-color 0.2s ease;
  }
  
  .dropzone-active {
    background-color: #f0f0f0;
  }
  
  /* Optional styles for the example components */
  .draggable-item {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-bottom: 8px;
  }
  
  .drop-area {
    border: 2px dashed #ccc;
    padding: 16px;
    border-radius: 4px;
  }