/* Layout.css */

.layout-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  position: absolute;
  /* This helps avoid any potential nesting issues */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  /* Prevent scrollbars at the container level */
}

/* Header styling */
.layout-header {
  min-height: 18px;
  display: flex;
  align-items: center;
  width: 100%;
}

/* Main content area - includes config list, content, and stack list */
.layout-main-content {
  display: flex;
  flex: 1;
  width: 100%;
}

/* Config list with 300px width */
.layout-config-list {
  width: 300px;
  min-width: 300px;
  overflow-y: auto;
  padding: 1rem;
  
}

/* Main content area - will take remaining space */
.layout-content {
  flex: 1;
  overflow: auto;
  padding: 1rem;
  background-color: #f5f5f5;
  /* Light gray background */
}

/* Stack list with 150px width */
.layout-stack-list {
  width: 300px;
  min-width: 300px;
  overflow-y: auto;
  padding: 1rem;
}

/* Dock at the bottom */
.layout-dock {
  min-height: 50px;
  padding: 0.5rem;
  width: 100%;
}

/* Responsive adjustments for smaller screens */
@media screen and (max-width: 768px) {
  .layout-main-content {
    flex-direction: column;
  }

  .layout-config-list,
  .layout-stack-list {
    width: 100%;
    min-width: 100%;
    
  }
}