.noselect {
  user-select: none;
  /* Prevents text selection */
  -webkit-user-select: none;
  /* For Safari browsers */
  -ms-user-select: none;
  /* For older versions of Edge/IE */
}

.dateInfo {
  font-size: 0.7em;
  color: var(--neutral-dark);
  /* Matches gray */
}


/* Main frame container */
.frame {
  position: absolute;
  border: 2px solid transparent;
  transition: border-color 0.3s ease;
  /* Smooth transition */
}

.frame:hover {
  border-color: #FF5733;
  /* Change this to your desired color */
}

.frame .top-title {
  flex: 1;
  z-index: 1000;
  font-size: 0.9em;
  font-weight: 600;
  margin: 0.4em 0;
  color: var(--text-color-primary); 
}

/* Top bar for dragging */
.frame .top-bar-left {
  position: absolute;
  top: 8px;
  left: 4px;
  width: calc(40% - 8px);
  height: 20px;
  cursor: move;
  /*background-color: rgba(255, 0, 0, 0.1);*/
  z-index: 10;
}
.frame .top-bar-right {
  position: absolute;
  top: 8px;
  right: 4px;
  width: calc(40% - 8px);
  height: 20px;
  cursor: move;
  /*background-color: rgba(255, 0, 0, 0.1);*/
  z-index: 10;
}
.frame .top-bar {
  top: 8px;
  height: 25px;
  cursor: move;
  /*background-color: rgba(255, 0, 0, 0.1);*/
  z-index: 10;
}


.frame .top-edge {
  position: absolute;
  top: 0px;
  left: 4px;
  width: calc(100% - 8px);
  height: 4px;
  cursor: s-resize;
  /*background-color: rgba(0, 0, 255, 0.1);*/
  z-index: 10;
}

/* Left edge: single-axis resize */
.frame .left-edge {
  position: absolute;
  top: 4px;
  left: 0;
  width: 4px;
  height: calc(100% - 4px);
  cursor: w-resize;
  /*background-color: rgba(0, 0, 255, 0.1);*/
  z-index: 15;
}

/* Right edge: single-axis resize */
.frame .right-edge {
  position: absolute;
  top: 4px;
  right: 0;
  width: 4px;
  height: calc(100% - 4px);
  cursor: e-resize;
  /*background-color: rgba(255, 0, 0, 0.1);*/
  z-index: 15;
}

/* Bottom edge: single-axis resize */
.frame .bottom-edge {
  position: absolute;
  bottom: 0;
  left: 4px;
  width: calc(100% - 4px);
  height: 4px;
  cursor: s-resize;
  /*background-color: rgba(0, 255, 0, 0.1);*/
  z-index: 15;
}

/* Corner handles (common) */
.corner-handle {
  position: absolute;
  width: 8px;
  height: 8px;
  /*background-color: blue;*/
  z-index: 20;
}

/* Each corner with unique positioning + cursor */
.corner-handle.top-left {
  top: 0;
  left: 0;
  cursor: nw-resize;
}

.corner-handle.top-right {
  top: 0;
  right: 0;
  cursor: ne-resize;
}

.corner-handle.bottom-left {
  bottom: 0;
  left: 0;
  cursor: sw-resize;
}

.corner-handle.bottom-right {
  bottom: 0;
  right: 0;
  cursor: se-resize;
}


/* allow switchIcon to appear on hover */
.hover-wrapper {
  display: none;
  /* Initially hide the icon */
}

.icon-container:hover .hover-wrapper {
  display: flex;
  /* Show the icon only on hover */
  align-items: center;
  justify-content: center;
}

.drawerBase {
  position: absolute;
  /* Absolute positioning within the parent */
  /* 4px from the top of the parent */
  width: 150px;
  /* Fixed width */
  height: 250px;
  /* Fixed width */
  /*max-height: calc(100% - 8px); /* Constrained height */
  overflow-y: auto;
  /* Allow scrolling if content exceeds the height */
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
  /* Smooth animations */
  background-color: rgb(247, 253, 255);
  /* Customize background */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  /* Optional shadow */
  border: 1px solid rgba(0, 0, 0, 0.1);
  /* Optional border */
}

.editorShadow {box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);}

.group-separators>*:not(:last-child) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  /* Use red dashed lines for visibility */
}
.group {
  width: 100%;
  border-radius: 10px;
  background-color: green;
  background-color: rgba(0, 0, 0, 0.05);
  /* Use red dashed lines for visibility */
}

.setup-bg {
  background-color: rgba(255, 255, 255, 0.489);
}

.toolbox {
  background-color: rgba(0, 0, 0, 0.03);
}
.border-top {
  border-top: 1px solid rgba(0, 0, 0, 0.2);
}
.border-bottom {
  border-top: 1px solid rgba(0, 0, 0, 0.2);
}
.drawerLeft {
  left: 4px;
  /* Anchored to the left edge of the parent */
  transform: translateX(-100%);
  /* Initially hidden */
}

.drawerLeft.open {
  transform: translateX(0);
  /* Slide into view */
}

.drawerRight {
  right: 4px;
  /* Anchored to the right edge of the parent */
  transform: translateX(100%);
  /* Initially hidden */
}

.drawerRight.open {
  transform: translateX(0);
  /* Slide into view */
}

.editorTitle {
  text-align: center;
  font-weight: bold;
  font-size: 18px;
  background-color: rgba(0, 0, 0, 0.05);
  height: 48px;
  max-height: 48px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.componentTitle {
  text-align: center;
  font-weight: bold;
  font-size: 15px;

}