:root {
    /* Extended Colors for Containers */
    --container-bg-light: #ffffff;
    /* Neutral light background */
    --container-bg-muted: #f9f9f9;
    /* Muted background for shaded containers */
    --container-bg-highlight: #fffbcc;
    /* Highlighted content */
    --container-bg-emphasis: #e7f1ff;
    /* Emphasis tone for callout boxes */
    --container-bg-dark: #333333;
    /* Dark surface for overlays */

    /* Filets / Borders */
    --filet-light: #e0e0e0;
    /* Subtle borders */
    --filet-muted: #b0b0b0;
    /* Borders for shaded or muted containers */
    --filet-strong: #007bff;
    /* Borders for emphasis containers */
    --filet-highlight: #ffcc00;
    /* Decorative borders for highlighted content */

    /* Overlay Colors */
    --overlay-light: rgba(255, 255, 255, 0.8);
    /* Light overlay */
    --overlay-dark: rgba(0, 0, 0, 0.6);
    /* Dark overlay */
    --overlay-primary: rgba(0, 123, 255, 0.3);
    /* Primary tone for hero sections */
}

/* optional background */
.bg-none {
    background: none;
    /* No background applied */
}

.filet-none {
    border: none;
    /* Optionally remove borders for clean transparency */
}

.fade-in {
    animation: fadeIn 0.5s ease-in-out;
    /* Duration: 0.5s, Smooth easing */
}

/* Opacity Levels */
.opacity-100 {
    opacity: 1;
    /* Fully opaque */
}

.opacity-75 {
    opacity: 0.75;
    /* 75% opacity */
}

.opacity-50 {
    opacity: 0.5;
    /* 50% opacity */
}

.opacity-25 {
    opacity: 0.25;
    /* 25% opacity */
}

.opacity-0 {
    opacity: 0;
    /* Fully transparent */
}

/* Background Styles */
.bg-light {
    background-color: var(--container-bg-light);
}

.bg-muted {
    background-color: var(--container-bg-muted);
}

.bg-highlight {
    background-color: var(--container-bg-highlight);
}

.bg-emphasis {
    background-color: var(--container-bg-emphasis);
}

.bg-dark {
    background-color: var(--container-bg-dark);
}

/* Border Styles */
.filet-light {
    border: 1px solid var(--filet-light);
}

.filet-muted {
    border: 1px solid var(--filet-muted);
}

.filet-strong {
    border: 2px solid var(--filet-strong);
}

.filet-highlight {
    border: 2px solid var(--filet-highlight);
}

/* Overlay Styles */
.overlay-light {
    background-color: var(--overlay-light);
}

.overlay-dark {
    background-color: var(--overlay-dark);
}

.overlay-primary {
    background-color: var(--overlay-primary);
}

/* Container Styles */

/* Plain Container */
.plain-container {
    background-color: var(--container-bg-light);
    border: 1px solid var(--filet-light);
    padding: 1em;
    border-radius: 4px;
}

/* Boxed Container */
.boxed-container {
    background-color: var(--container-bg-muted);
    border: 1px solid var(--filet-muted);
    padding: 1.5em;
    border-radius: 6px;
}

/* Highlighted Box */
.highlighted-box {
    background-color: var(--container-bg-highlight);
    border: 2px solid var(--filet-highlight);
    padding: 1.5em;
    border-radius: 6px;
}

/* Callout Box */
.callout-box {
    background-color: var(--container-bg-emphasis);
    border: 2px solid var(--filet-strong);
    padding: 1.5em;
    border-radius: 8px;
}

/* Shaded Background Container */
.shaded-background {
    background-color: var(--container-bg-muted);
    border: 1px solid var(--filet-light);
    padding: 1.5em;
    border-radius: 6px;
}

/* Left-Aligned Container */
.left-aligned {
    text-align: left;
    background-color: var(--container-bg-light);
    border: 1px solid var(--filet-light);
    padding: 1em;
    border-radius: 4px;
}

/* Right-Aligned Container */
.right-aligned {
    text-align: right;
    background-color: var(--container-bg-light);
    border: 1px solid var(--filet-light);
    padding: 1em;
    border-radius: 4px;
}

/* Center-Aligned Container */
.center-aligned {
    text-align: center;
    background-color: var(--container-bg-light);
    border: 1px solid var(--filet-light);
    padding: 1em;
    border-radius: 4px;
}

/* Justified Text Block */
.justified-text-block {
    text-align: justify;
    background-color: var(--container-bg-light);
    border: 1px solid var(--filet-light);
    padding: 1em;
    border-radius: 4px;
}

/* Scrollable Box */
.scrollable-box {
    background-color: var(--container-bg-light);
    border: 1px solid var(--filet-muted);
    padding: 1em;
    border-radius: 6px;
    max-height: 200px;
    overflow-y: auto;
}

/* Overlay Text Box */
.overlay-text-box {
    background-color: var(--overlay-dark);
    padding: 2em;
    color: var(--text-color-secondary);
    border-radius: 6px;
}

/* rounded */

.rounded-full {
    border-radius: 50%;
    /* Full circle */
}

.rounded-pill {
    border-radius: 9999px;
    /* Fully rounded for pill-shaped elements */
}

/* tray.css */
.tray {
    display: flex;
    flex-direction: row;
    gap: 12px;
    align-items: center;
    height: '12px';
    cursor: default;
}

@media (max-width: 600px) {
    .rounded-1 {
        border-radius: 0.5px;
    }

    .rounded-2 {
        border-radius: 1px;
    }

    .rounded-3 {
        border-radius: 2px;
    }

    .rounded-5 {
        border-radius: 3px;
    }

    .rounded-8 {
        border-radius: 5px;
    }
}


/*LAYOUTING*/

.flex-align {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.flex-align.row {
    flex-direction: row;
}

.flex-align.column {
    flex-direction: column;
    justify-content: center;
    /* Optional: adjust as needed for column layout */
}





/* QWANYX UI */
/*
   list of vertical-align options without explanations:

    baseline
    top
    middle
    bottom
    sub
    super
    text-top
    text-bottom
    <percentage> (e.g., 20%)
    inherit
    sub
  */

.ideheader {
    padding: 7px;
    color: var(--text-color-primary);
    /* Matches default black */
    background-color: var(--container-bg-emphasis);
}

.ideheaderTypo {
    font-size: 1em;
    margin-top: -0.27em;

}


.icon-container {
    display: inline-flex;
    /* Keep inline and flex-aligned */
    align-items: center;
    /* Vertically center icon */
    justify-content: center;
    /* Horizontally center icon */
    line-height: 1;
    /* Normalize line height */
    vertical-align: middle;
    /* Align with text */
    margin: 0;
    padding: 0;
}

.qwantum-list {
    isolation: isolate;
}

.icon-in-list {
    border: 1px solid #ccc;
    /* Thin border with light gray color */
    border-radius: 4px;
    /* Rounded corners */
    /* Optional: adds some spacing inside the border */
}
.icon-in-list-noborder {
    /* Thin border with light gray color */
    border-radius: 4px;
    /* Rounded corners */
    /* Optional: adds some spacing inside the border */
}

.buttonTypo {
    font-size: 1em;
    display: inline-block;
    vertical-align: middle;
    /* Align text vertically with icon */
    margin: 0;
    /* Remove default margins */
    line-height: 1;
    /* Normalize text line height */
}

button {
    display: inline-flex;
    /* Align items within button horizontally */
    align-items: center;
    /* Vertically center items */
    gap: 4px;
    /* Adjust spacing between icon and text */
    background: none;
    border: none;
    padding: 0;
    /* Remove padding for exact alignment */
    margin: 0;
    /* Ensure no spacing interference */
}

.dock {
    color: 'white';
    background: #d7e5f9;
    /* Subtle blue gradient */
    border-radius: 12px;
    /* Rounded corners */
    padding: 0px;
    /* Optional: Space inside the container */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    /* Optional: Subtle shadow for depth */
    display: flex;
    /* Optional: Flexbox for layout */
    justify-content: center;
    /* Center content horizontally */
    align-items: center;
    /* Center content vertically */
}

.menubar {
    position: absolute;
    /* Ensures the component can be positioned over others */
    top: 0;
    left: 0;
    width: 100%;
    /* Adjust width as needed */
    height: 23px;
    /* Adjust height as needed */
    z-index: 10;
    /* Places it above other components */
    padding-left: 10px;
    padding-right: 10px;
    background: rgba(0, 0, 0, 0.6);
    /* Background color */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.06);
    /* Subtle shadow */
    display: flex;
    /* Optional: Center content */
    justify-content: center;
    /* Optional: Center horizontally */
    align-items: center;
    /* Optional: Center vertically */
}

.responsive-div {
    width: 100%;         /* Default width for mobile devices */
    max-width: 1200px;   /* Maximum width the div can reach */
    margin: 0 auto;      /* Center the div horizontally */
    
    /* Tablet devices (768px and up) */
    @media screen and (min-width: 768px) {
      width: 80%;
    }
    
    /* Desktop devices (992px and up) */
    @media screen and (min-width: 992px) {
      width: 70%;
    }
    
    /* Large desktop devices (1200px and up) */
    @media screen and (min-width: 1200px) {
      width: 60%;
    }
  }

  .responsive-icon {
    width: 64px;         /* Default width for mobile devices */ 
    margin: 0 auto;      /* Center the div horizontally */

    
    /* Tablet devices (768px and up) */
    @media screen and (min-width: 768px) {
        width: 96px;
    }
    
    /* Desktop devices (992px and up) */
    @media screen and (min-width: 992px) {
      width: 100px;
    }
    
    /* Large desktop devices (1200px and up) */
    @media screen and (min-width: 1200px) {
      width: 128px;
    }
  }