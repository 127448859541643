:root {
  --grid-gap: 16px;
  --grid-padding: 16px;
}

.dynamic-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: var(--grid-gap);
  padding: var(--grid-padding);
}

.grid-slot {
  width: 100%; /* Grid slot inherits the full width from its column */
  aspect-ratio: 4 / 3; /* Ensures each slot maintains a 4:3 ratio */
  background-color: #f4f4f4;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ccc;
  border-radius: 8px;

  /* Enforce equal vertical spacing: add margin to simulate equal space if needed */
}

.dynamic-grid.align-left {
  justify-items: start;
}

.dynamic-grid.align-center {
  justify-items: center;
}

.dynamic-grid.align-right {
  justify-items: end;
}

.small-slot {
  width: 40px;
  height: 40px;
  margin: 4px;
  background-color: #ddd;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
}